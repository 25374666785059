@import './variables';
@import './layouts';

// HEADINGS

.sans-head-1, h1,
.sans-head-2, h2,
.sans-head-3, h3 {
    font-family: $sans-serif-medium;
    font-weight: $sans-medium;
}

h1,
.sans-head-1 {
    font-size: 40px;
    letter-spacing: -0.4px;
    line-height: 50px;
}

h2,
.sans-head-2 {
    display: inline-block;
    font-size: 26px;
    letter-spacing: 1.37px;
    line-height: 42px;
    margin-bottom: 24px;
    position: relative;
    text-align: center;
    text-transform: uppercase;

    &::after {
        background: $grey;
        bottom: -20px;
        content: '';
        height: 2px;
        left: 50%;
        position: absolute;
        transform: translateX(-50%);
        width: 100px;
    }
}

h3,
.sans-head-3 {
    font-size: 18px;
    letter-spacing: 0.77px;
    line-height: 18px;
    text-transform: uppercase;
}

.serif-head-1,
.serif-head-2,
.serif-head-3 {
    font-family: $serif;
}

.serif-head-1 {
    font-weight: $serif-semibold;
    font-size: 44px;
    line-height: 44px;
}

.serif-head-2 {
    color: $red;
    font-size: 40px;
    line-height: 44px;
}

.serif-head-3 {
    font-weight: $serif-semibold;
    font-size: 32px;
    line-height: 40px;
}

// OTHER TYPE STYLES

.typography {
    font-family: $sans-serif-normal;
    &-body {
        font-size: 16px;
        line-height: 28px;
    }

    &-body-large {
        font-size: 18px;
        line-height: 30px;
    }

    &-subtitle {
        font-size: 16px;
        font-family: $sans-serif-medium;
        font-weight: $sans-medium;
        line-height: 28px;
    }

    &-small {
        font-size: 13px;
    }
    
    &-small-letter {
        font-size: 13px;
        letter-spacing: 0.8px;
    }

    &-small-upper {
        font-size: 13px;
        letter-spacing: 0.8px;
        text-transform: uppercase;
    }

    &-intro-paragraph {
        font-size: 24px;
        line-height: 38px;
    }
}

// TYPE COLORS

.typography-white {
    &,
    * {
        color: $white;

        &::selection {
            background-color: $mediumergrey;
        }
    }
}

// ELEMENTS

p {
    // Default font size/line height:
    font-size: 16px;
    line-height: 28px;
}

p + p {
    margin-top: 20px;
}

// LINKS

a {
    border-bottom: 1px dotted;
    font-size: 16px;
    font-family: $sans-serif-normal;
    font-weight: normal;
    line-height: 28px;
    text-decoration: none;

    &,
    *,
    &:after {
        color: $red;
        transition: .5s ease-in-out;
    }

    &:hover {
        border-bottom: 1px solid;
        
        &,
        *,
        &:after {
            color: $darkred;
        }
    }
}

// CHEVRON (default style is just border)

%data-chevron-true,
p[data-chevron="true"],
a[data-chevron="true"] {
    border: 0;
    margin-right: 16px; 

    [class='linkText'] {
        border-bottom: 1px solid transparent;
    }

    &:hover {
        [class='linkText'] {
            border-bottom-color: $red;
        }
    }

    &::after {
        content: '\f054';
        font-family: $fontAwesome;
        font-size: 12px;
        margin-left: 8px;
        position: static;
    }

    &[data-size='small'] {
        &::after {
            position: relative;
            top: -1px;
        }
    }
}

// COLORS

a[data-color="white"] {    
    &,
    *,
    &::after {
        border-color: $white;
        color: $white;
    }

    &:hover {
        &,
        *,
        [class='linkText'],
        &::after {
            border-color: $white;
            opacity: .8;
        }
    }
}

a[data-color="grey"] {   
    &,
    *,
    &::after {
        border-color: $grey;
        color: $grey;
    }

    &:hover {
        &,
        *,
        &::after {
            border-color: $darkred;
            color: $darkred;
        }
    }
}

// SIZES

a[data-size="large"] {
    font-size: 18px;
}

a[data-size="small"] {
    font-size: 14px;
    font-family: $sans-serif-medium;
    font-weight: $sans-medium;
    line-height: 14px;
    letter-spacing: 0.44px;
}

a[data-size="medium"],
a[data-size="small"] {
    &::after {
        font-size: 10px;
    }
}

@media (max-width: $mobile) {
    h1,
    .sans-head-1 {
        font-size: 22px;
        letter-spacing: -0.22px;
        line-height: 32px;
    }

    h2,
    .sans-head-2 {
        font-size: 22px;
        line-height: 32px;

        &::after {
            bottom: -16px;
        }
    }
}