@import './variables';

main {
    display: block; // IE fix
    position: relative;
}

.single-col-wide {
    .single-col-inner,
    .twocol,
    .threecol {
        max-width: 1488px;
    }
}
.single-col{
    .coveo-main-section{
        svg{
            max-width: 30px;
            animation-name:  svg-animation;
            animation-duration: 2s;
        }
    }
}

@keyframes svg-animation {
    0%   { max-width: 0px;}
    90%  { max-width: 0px;}
    100% { max-width: 30px;}
  }

.single-col,
.single-col-wide {
    > .richtext {
        @extend .single-col-inner;
    }
}

.single-col .single-col-inner,
.twocol,
.threecol {
    max-width: 1243px;
}

.single-col-inner,
.twocol,
.threecol {
    margin: 0 auto;
    padding: 0 24px;

    .single-col-inner {
        padding-left: 0;
        padding-right: 0;
    }
}

.twocol {
    display: flex;
    justify-content: space-between;

    &-large,
    &-small {
        padding-top: 32px;
        &:empty {
            display: none;
        }
        *[class*="CalloutBox_defaultContainer"] {
            margin-top: 60px;
        }
    }
    
    &-large {
        flex-shrink: 0;
        width: calc(70% - 40px);
    }
    
    &-small {
        width: calc(30% - 40px);
        position: relative;
    }
}

.threecol {
    display: grid;
    grid-column-gap: 48px;
    grid-template-columns: 1fr 2fr 1fr;
}

%containerSpacing {
    &:not(:first-child) {
        margin-top: 48px;
    }
    
    &:not(:last-child) {
        margin-bottom: 48px;
    }
}

@media (max-width: $desktop-smaller) {
    .twocol {        
        &-large {
            width: calc(70% - 24px);
        }
        
        &-small {
            width: calc(30% - 24px);
        }
    }
}

@media (max-width: $mobile-larger) {
    .twocol,
    .threecol {
        display: block;

        > * + * {
            margin-top: 48px;
        }
        
        > * {
            .single-col-inner {
                padding-right: 0;
                padding-left: 0;
            }
        }
    }

    .twocol {
        &-large,
        &-small {
            width: 100%;
        }

        &-large {
            > *:first-child {
                margin-top: 0;
                padding-top: 0;
            }
        }
    }
}

// CSS to go with logic in Layout.js
[apm="true"]{
        .twocol-small{
            padding-top: 0;     
        }
        .twocol-large{
            padding-top: 0;
            .richtext{
                margin-top: -27px;
            }
        } 
        
}
[tcl="true"]{
    .twocol-large{
        padding-top: 0;
        .richtext{
            margin-top: 30px;
        }
    } 
}



.mb-1{
    margin-bottom: 1em !important;
  }
.mb-2{
    margin-bottom: 2em !important;
  }
.mb-3{
    margin-bottom: 3em !important;
  }
.mb-4{
    margin-bottom: 4em !important;
  }

.mt-1{
    margin-top: 1em !important;
  }
.mt-2{
    margin-top: 2em !important;
  }
.mt-3{
    margin-top: 3em !important;
  }
.mt-4{
    margin-top: 4em !important;
  }



.CTABarSpotlight_button-border-removal{
    a{
        border-color: transparent;
    }
}

.twocol-small-left-align{
    display:flex;
    flex-direction: column;
    align-items: flex-start;
} 

.twocol-padding-bottom{
    padding-bottom: 20px;
}