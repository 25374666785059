@import '../../scss/layouts';
@import '../../scss/typography';

.container {
    margin: 48px auto;
    max-width: 1286px;
    padding: 0 24px;
    display: flex;
    flex-direction: column;
    align-items: center;

    

    .heading {
        display: block;
        margin-bottom: 64px;
    }

    [class='list-widget'] {
        li {
            &::before {
                content: none;
            }

            &:nth-child(n + 16) {
                display: none;
            }

            & + li {
                margin-top: 32px;
            }

            [class='event-date'],
            [class='event-summary'] {
                flex-shrink: 0;
            }

            [class='event-date'] {
                @extend .typography-small-upper;
                font-family: $sans-serif-bold;
                font-weight: $sans-bold;
                line-height: 22px;
                margin-top: 2px;
                text-align: right;
                width: 100px;
            }

            [class='event-summary'] {
                margin-left: 24px;
                width: calc(100% - 124px);
            }
        }
    }

    // Card view:
    [class*='col-sm-6 flex-col event'] {
        border: 1px solid $lightgrey;
        display: inline-block;
        min-height: 350px;
        padding: 10px;
        text-align: center;
        vertical-align: top;
        width: calc(25% - 12px);

        &:not(:last-child) {
            margin-right: 16px;
        }

        [class='date'] {
            border-bottom: 2px solid $mediumgrey;
            display: block;
            font-size: 20px;
            line-height: 28px;
            margin-bottom: 16px;
            padding-bottom: 16px;
        }

        h3 {
            text-transform: none;

            &:not(:last-child) {
                margin-bottom: 16px;
            }
        }
    }
}


@media (max-width: $smallContainer) {
    .container {
        max-width: 948px;
    }
}

@media (max-width: $mobile-larger) {
    .container {        
        // Card view:
        [class*='col-sm-6 flex-col event'] {
            width: calc(50% - 12px);

            &:not(:last-child) {
                margin-bottom: 16px;
                margin-right: 0;
            }

            &:nth-child(even) {
                margin-right: 16px;
            }

            // Exclude second to last
            &:nth-last-child(2) {
                margin-bottom: 0;
            }
        }
    }
}

@media (max-width: $mobile) {
    .container {
        .heading {
            margin-bottom: 42px;
        };
    }
}

@media (max-width: $mobile-smaller) {
    .container {        
        // Card view:
        [class*='col-sm-6 flex-col event'] {
            width: 100%;

            &:nth-child(even) {
                margin-right: 0;
            }

            // Exclude second to last
            &:nth-last-child(2) {
                margin-bottom: 16px;
            }
        }
    }
}

.seeAllEvents{
    margin-top: 2em;
    border-color: transparent;
}